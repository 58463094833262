<template>
  <div>
    <title>SIM INVENTORY ~ DATA LIST OF FINISH GOOD ITEMS</title>
    <!-- Content Header (Page header) -->
    <section class="content-header">
      <h1>
        Data List of Finish Good Items
        <br />
        <h4>The following is a list of transaction data lists for finished good items</h4>
        <!-- <small>Control panel</small> -->
      </h1>
      <ol class="breadcrumb">
        <li>
          <a href="#"><i class="fa fa-dashboard"></i> Home</a>
        </li>
        <li class="active">Dashboard</li>
      </ol>
    </section>

    <!-- Main content -->
    <section class="content">
      <!-- Small boxes (Stat box) -->
      <div class="row">
        <div class="col-md-12">
          <div class="box box-danger">
            <div class="box-header with-border">
              <h3 class="box-title">Filter data</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  Show &nbsp; :
                  <select
                    v-model="pageSize"
                    @change="handlePageSizeChange($event)"
                  >
                    <option v-for="size in pageSizes" :key="size" :value="size">
                      {{ size }}
                    </option>
                  </select>
                  results
                </div>
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by Receipt Number"
                    class="form-control"
                    v-model="namesearch"
                  />
                </div>
                <div class="col-xs-1">
                  <button
                    @click="searchdata()"
                    type="button"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
    
      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header">
              <h3 class="box-title">List Finish Good Items</h3>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <!-- <th>Idx</th> -->
                      <th>Receipt Number</th>
                      <th>Receipt Type</th>
                      <th>BL Number</th>
                      <th>Receipt Date</th>
                      <th>Register Number</th>
                      <th>Register Date</th>
                      <th>Supplier Name</th>
                      <th>Notes</th>
                      <th>Action</th>
                      <th>Detail</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata, index) in list_paged"
                      :key="classdata.id"
                    >
                      <td>{{ index + 1 + (page - 1) * pageSize }}</td>
                      <!-- <td>{{ classdata.idx }}</td> -->
                      <td>{{ classdata.receipt_number }}</td>
                      <td>{{ classdata.jenis_penerimaan }}</td>
                      <td>{{ classdata.bl_number }}</td>
                      <td>{{ classdata.receipt_date }}</td>
                      <td>{{ classdata.bc_no }}</td>
                      <td>{{ classdata.bc_date }}</td>
                      <td>{{ classdata.nama_supplier }}</td>
                      <td>{{ classdata.notes }}</td>

                      <td>
                        <router-link :to="'/edit-finishgood/' + classdata.id">
                          <button title="Edit Finish Good" class="btn btn-info">
                            <i class="fa fa-edit"></i>
                          </button>
                        </router-link>
                        &nbsp;<br><p></p>

                        <button
                          title="Delete finish good items"
                          @click="deleteData(classdata.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                      <td>
                        <router-link
                          :to="
                            '/add-finishgood-detail/' +
                              classdata.auto_tpb +
                              '/' +
                              classdata.bc_no +
                              '/' +
                              classdata.receipt_number
                          "
                        >
                          <button
                            title="Add Details Finish Good Items"
                            class="btn btn-success"
                          >
                            <i class="fa fa-plus"></i> Details Finish Good
                          </button>
                        </router-link>
                        &nbsp; <br><p></p>
                        <router-link
                          :to="
                            '/list-finishgood-detail/' +
                              classdata.receipt_number
                          "
                        >
                          <button
                            title="View Details Finish Good Items"
                            class="btn btn-info"
                          >
                            <i class="fa fa-list"></i> List Detail Finish Good
                          </button>
                        </router-link>
                        &nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>

                <div>
                  <v-pagination
                    v-model="page"
                    :pages="count"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="handlePageChange"
                  />
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="box box-danger">
            <div class="box-header with-border">
              <h3 class="box-title">Filter data</h3>
            </div>
            <div class="box-body">
              <div class="row">
                <div class="col-xs-3">
                  Show &nbsp; :
                  <select
                    v-model="pageSize2"
                    @change="handlePageSizeChange2($event)"
                  >
                    <option v-for="size2 in pageSizes2" :key="size2" :value="size2">
                      {{ size2 }}
                    </option>
                  </select>
                  results
                </div>
                <div class="col-xs-5">
                  <input
                    type="text"
                    autocomplete="off"
                    placeholder="Search by Batch Code"
                    class="form-control"
                    v-model="namesearch2"
                  />
                </div>
                <div class="col-xs-1">
                  <button
                    @click="searchDataFinishGoodDariProsesProduksi()"
                    type="button"
                    class="btn btn-info"
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <div v-if="loading" class="load">
        <img width="130" src="@/assets/loader.gif" alt="" />
      </div>
      <br />

      <div class="row">
        <div class="col-xs-12">
          <div class="box box-primary">
            <div class="box-header">
              <i class="fa fa-list-ul"></i>
              <h3 class="box-title">Finish Good Detail Data from the Production Process</h3>
            </div>

            <!-- /.box-header -->
            <div class="box-body">
              <div class="table-responsive">
                <table id="mydata" class="table table-bordered table-striped">
                  <thead>
                    <tr>
                      <th>No.</th>
                      <th>Batch Code</th>
                      <th>Process Date</th>
                      <th>Stock</th>
                      <th>Process Results</th>
                      <th>Process Results Out</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(classdata2, index2) in list_paged2"
                      :key="classdata2.id"
                    >
                      <td>{{ index2 + 1 + (page - 1) * pageSize2 }}</td>
                      <td>{{ classdata2.nomor_produksi }}</td>
                      <td>{{ classdata2.tanggal_proses }}</td>
                      <td>{{ classdata2.stok }}</td>
                      <td>{{ classdata2.hasil_proses }}</td>
                      <td>{{ classdata2.hasil_proses_out }}</td>
                      <td>
                        <router-link
                          :to="'/edit-detailfinishgood-dariprosesproduksi/' + classdata2.id"
                        >
                          <button
                            title="Edit Finish Good Details of the Production Process"
                            class="btn btn-info"
                          >
                            <i class="fa fa-edit"></i> Edit Detail Finish Good
                          </button>
                        </router-link>&nbsp;
                        <!-- <button
                          title="Hapus Finish Good Detail dari Proses Produksi"
                          @click="deleteData(classdata2.id)"
                          class="btn btn-danger"
                        >
                          <i class="fa fa-trash"></i>
                        </button> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
                <v-pagination
                  v-model="page"
                  :pages="count"
                  :range-size="1"
                  active-color="#DCEDFF"
                  @update:modelValue="handlePageChange2"
                />
              </div>
            </div>
            <!-- /.box-body -->
          </div>
        </div>
      </div>
      <!-- /.row -->
      <!-- Main row -->
    </section>
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";

import swal from "sweetalert";
import axios from "axios";

export default {
  name: "vread",
  components: {
    VPagination
  },
  data() {
    return {
      isShow: false,
      nmsrc: true,
      loading: false,
      namesearch: "",
      namesearch2: "",
      list_paged: [],
      list_paged2: [],
      page: 1,
      pagestat: 1,
      count: 1,
      pageSize: 10,
      pageSize2: 10,
      pageSizes: [10, 50, 100, 500],
      pageSizes2: [10, 50, 100, 500]
    };
  },
  created() {
    this.fetchData();
    this.fetchDataFinishGoodDariProsesProduksi();
  },
  methods: {
    handlePageChange(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchData();
    },
    handlePageChange2(value) {
      this.page = value;
      this.pagestat = 1;
      // window.scrollTo({ top: 0, behavior: "smooth" });
      this.fetchDataFinishGoodDariProsesProduksi();
    },

    handlePageSizeChange(event) {
      this.pageSize = event.target.value;
      this.page = 1;
      this.fetchData();
    },
    handlePageSizeChange2(event) {
      this.pageSize2 = event.target.value;
      this.page = 1;
      this.fetchDataFinishGoodDariProsesProduksi();
    },
    getRequestParams(page, pageSize) {
      let params = {};

      if (page) {
        params["page"] = page;
      }

      if (pageSize) {
        params["rowcount"] = pageSize;
      }

      return params;
    },
    async fetchData() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "penerimaan_fg_h/getalldatapenerimaan_fg_h?length=" +
        this.pageSize +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.list_paged = resp.data.data;
          // sessionStorage.setItem('bcno',this.$route.params.nobc)
          this.count = resp.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          }).then(function(isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    async fetchDataFinishGoodDariProsesProduksi() {
      this.loading = true;
      // const tokenlogin = localStorage.getItem("token");
      const tokenlogin = sessionStorage.getItem("token");
      const urlAPIget =
        this.$apiurl +
        "proses_produksi_d_dari_proses_produksi/getalldatastok_hasil_produksi?length=" +
        this.pageSize2 +
        "&page=" +
        this.page;
      const headers = {
        Accept: "application/json",
        Authorization: tokenlogin
      };
      axios
        .get(urlAPIget, { headers: headers })
        .then((resp) => {
          this.list_paged2 = resp.data.data;
          // sessionStorage.setItem('bcno',this.$route.params.nobc)
          this.count = resp.data.datatotalcount;
          this.loading = false;
        })
        .catch((err) => {
          console.log(err.reponse.data);
          swal({
            icon: "warning",
            title: "Warning",
            text: err.response.data
          }).then(function(isConfirm) {
            if (isConfirm) {
              window.location.href = "/";
            }
          });
        });
    },
    searchdata() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch == "") {
        this.$router.push({ name: "finishgoodheader" });
        this.loading = true;
        this.fetchData();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIget =
          this.$apiurl +
          "penerimaan_fg_h/getalldatapenerimaan_fg_h?cari=" +
          this.namesearch +
          "&length=" +
          this.pageSize;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            this.list_paged = resp.data.data;
            this.count = resp.data.datatotalcount;
            this.loading = false;
            this.nmsrc = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data
            }).then(function(isConfirm) {
              if (isConfirm) {
                window.location.href = "/";
              }
            });
          });
      }
    },
    searchDataFinishGoodDariProsesProduksi() {
      this.loading = true;
      this.nmsrc = true;
      // const params = this.getRequestParams(this.page);
      const params = this.getRequestParams(this.pagestat);
      if (this.namesearch2 == "") {
        window.location.reload()
        this.loading = true;
        this.fetchDataFinishGoodDariProsesProduksi();
      } else {
        // const tokenlogin = localStorage.getItem("token");
        const tokenlogin = sessionStorage.getItem("token");
        const urlAPIget =
          this.$apiurl +
          "proses_produksi_d_dari_proses_produksi/getalldatastok_hasil_produksi?cari=" +
          this.namesearch2 +
          "&length=" +
          this.pageSize2;
        axios
          .get(urlAPIget, { params, headers: { Authorization: tokenlogin } })
          .then((resp) => {
            this.list_paged2 = resp.data.data;
            this.count = resp.data.datatotalcount;
            this.loading = false;
            this.nmsrc = false;
          })
          .catch((err) => {
            swal({
              icon: "warning",
              title: "Warning",
              text: err.response.data
            }).then(function(isConfirm) {
              if (isConfirm) {
                window.location.href = "/";
              }
            });
          });
      }
    },
    deleteData(id) {
      this.loading = true;
      swal({
        title: "Are you sure ?",
        text: "The selected data will be deleted and cannot be restored",
        icon: "warning",
        buttons: true,
        dangerMode: true
      }).then((willdelete) => {
        if (willdelete) {
          // const tokenlogin = localStorage.getItem("token");
          const tokenlogin = sessionStorage.getItem("token");
          const urlAPIbrgdel =
            this.$apiurl + "penerimaan_fg_d/hapuspenerimaan_fg_d/" + id;
          const headers = {
            Accept: "application/json",
            Authorization: tokenlogin
          };
          axios
            .delete(urlAPIbrgdel, { headers: headers })
            .then((resp) => {
              if (resp.data.status == true) {
                swal({
                  icon: "success",
                  title: "Success",
                  text: "Delete data successfully"
                }).then(function() {
                  window.location.reload();
                });
              } else {
                swal({
                  icon: "warning",
                  title: "Warning",
                  text: "Delete data failed"
                }).then(function() {
                  window.location.reload();
                });
              }
            })
            .catch((err) => {
              swal({
                icon: "warning",
                title: "Warning",
                text: err.response.data
              }).then(function(isConfirm) {
                if (isConfirm) {
                  window.location.href = "/";
                }
              });
            });
        } else {
          this.loading = false;
        }
      });
    }
  }
};
</script>
<style>
.load {
  width: 20%;
  margin: 0 auto;
}
</style>
